<template>
  <div class="la-form-builder additional-doc-builder card">
    <fd-form class="modal-content-wrapper" @submit="submitForm">
      <modal-body>
        <div class="container fluid">
          <h3 class="mt-3 mb-4">Additional Documents</h3>
          <!-- Document Fields -->
          <div class="document-fields px-1">
            <h4 class="mb-2">Document Fields</h4>

            <div class="row mxn-1">
              <document-field
                v-for="(field, index) in additionalDoc.fields"
                :key="index"
                :title.sync="additionalDoc.fields[index].title"
                :subTitle.sync="additionalDoc.fields[index].subTitle"
                :isRequired.sync="additionalDoc.fields[index].isCompulsory"
                class="col-12 sm-col-6 px-1 mb-2"
                @remove="removeField(additionalDoc.fields[index], index)"
              ></document-field>
            </div>
          </div>

          <!-- Actions -->
          <div class="px-1 my-4">
            <button type="button" class="btn main bordered" @click="addField">
              <i class="fas fa-plus-circle mr-1"></i>Add Field
            </button>
          </div>
        </div>
      </modal-body>

      <!-- Footer Actions -->
      <modal-footer class="row justify-content-end p-2">
        <button type="button" class="btn mr-1" @click="$emit('cancel')">
          Cancel
        </button>
        <button class="btn main medium">
          <i class="fas fa-pen mr-1"></i>
          Update Addtional Documents
        </button>
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { LoanApplicationDocFormModel as LADocFormModel } from "@/models";
import { loanApplicationDocForm as LADocFormAPI } from "@/api";

const MODE = {
  ADD: "add",
  EDIT: "edit"
};

export default {
  components: {
    DocumentField: () =>
      import("@/components/LoanApplicationFormBuilder/DocumentField")
  },
  mixins: [],
  props: {
    mode: {
      type: String,
      default: MODE.add
    },
    categoryId: {
      type: [String, Number]
    }
  },
  data: function () {
    return {
      additionalDoc: {},

      MODE,
      validator: {
        required: required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getAdditionalFields();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    async getAdditionalFields() {
      try {
        let data = await LADocFormAPI.getLADocAdditionalField();
        this.additionalDoc = LADocFormModel.getAdditionalToEdit(data.data);
        if (this.additionalDoc.length < 1) {
          this.additionalDoc = {
            fields: [
              {
                id: null,
                title: "",
                subTitle: ""
              }
            ]
          };
        }
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get additional documents data. Please try again later."
        });
        console.log(error);
        throw error;
      }
    },
    addField() {
      this.additionalDoc.fields.push({
        id: null,
        title: "",
        subTitle: ""
      });
    },
    removeField(docField, index) {
      if (this.$isStringEmpty(docField.id)) {
        this.removeFieldLocally(index);
      } else {
        this.removeFieldFromDb(docField.id);
      }
    },
    async removeFieldFromDb(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Remove Additional Document Field",
        message:
          "Are you sure you want to remove this field? This action cannot be undone.",
        confirmText: "Remove"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await LADocFormAPI.deleteLADocAdditionalField(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "The additional document field has been removed successfully."
          });

          await this.getAdditionalFields();

          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to remove this field. Please try again later."
          });
        }
      }
    },
    removeFieldLocally(index) {
      this.additionalDoc.fields = this.additionalDoc.fields.filter(
        (item, idx) => {
          return idx !== index;
        }
      );
    },
    submitForm() {
      this.$emit(
        "update",
        LADocFormModel.putAdditionalDocPayload(this.additionalDoc)
      );
    }
  }
};
</script>

<style lang="scss">
.additional-doc-builder {
  .document-fields {
    margin-top: 48px;
  }
}
</style>
